import React, {useContext} from "react";
import {Link} from "react-router-dom";
import SidebarMiniStatsCard, {Green, Red} from "./SidebarMiniStatsCard";
import rtkConnectivityIcon from "../../assets/images/rtk-connectivity.png";
import SatelliteLineChart from "../common/charts/charts2/SatelliteLineChart";
import DopLineChart from "../common/charts/charts2/DopLineChart";
import {ConnectionParameterService} from "../../services/ConnectionParameterService";
import {URLGenerationService} from "../../services/URLGenerationService";
import {BaseData} from "../../services/BaseService";
import {AppContext} from "../../App";

const NoUser = () => {
    return (
        <div className="wallet-mobile-sidebar-bg contract-height">
            <p className="text-white text-[16px] xl:text-[18px] mb-1.5 text-left custom-font-600 mt-[10px] lg:mt-0 hidden lg:block px-[25px]">
                Base view
            </p>
        </div>
    )
}

const SidebarBaseView = ({
                             baseId,
                             hotspotData,
                             baseData,
                             activityCount,
                             setActivityCount,
                             activities,
                         }: {
    baseId: any;
    hotspotData: BaseData,
    baseData: any;
    activityCount: any;
    setActivityCount: any;
    activities: any
}) => {
    const loggedIn = useContext(AppContext)?.fireBaseUser;

    return !loggedIn ? (<NoUser/>) : (
        <div className="mb-[30px] px-4 lg:mt-0 baseview-sidebar-mobile-bg">
            <div className="flex items-center gap-x-[10px]">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="24"
                    viewBox="0 0 23 24"
                    fill="none">
                    <g clipPath="url(#clip0_1_19069)">
                        <path
                            d="M11.3516 19.6369C13.3586 19.6369 15.2834 18.8396 16.7026 17.4204C18.1218 16.0012 18.9191 14.0764 18.9191 12.0693C18.9191 10.0623 18.1218 8.13744 16.7026 6.71825C15.2834 5.29905 13.3586 4.50176 11.3516 4.50176C9.34451 4.50176 7.41967 5.29905 6.00047 6.71825C4.58128 8.13744 3.78398 10.0623 3.78398 12.0693C3.78398 14.0764 4.58128 16.0012 6.00047 17.4204C7.41967 18.8396 9.34451 19.6369 11.3516 19.6369ZM11.3516 21.5288C6.12709 21.5288 1.89209 17.2938 1.89209 12.0693C1.89209 6.84487 6.12709 2.60986 11.3516 2.60986C16.576 2.60986 20.811 6.84487 20.811 12.0693C20.811 17.2938 16.576 21.5288 11.3516 21.5288ZM11.3516 13.9612C10.8498 13.9612 10.3686 13.7619 10.0138 13.4071C9.65898 13.0523 9.45966 12.5711 9.45966 12.0693C9.45966 11.5676 9.65898 11.0864 10.0138 10.7316C10.3686 10.3768 10.8498 10.1774 11.3516 10.1774C11.8533 10.1774 12.3345 10.3768 12.6893 10.7316C13.0441 11.0864 13.2434 11.5676 13.2434 12.0693C13.2434 12.5711 13.0441 13.0523 12.6893 13.4071C12.3345 13.7619 11.8533 13.9612 11.3516 13.9612Z"
                            fill="#249CF3"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1_19069">
                            <rect
                                width="22.7027"
                                height="22.7027"
                                fill="white"
                                transform="translate(0 0.717773)"/>
                        </clipPath>
                    </defs>
                </svg>
                <p className="text-[15px] xl:text-[16px] 2xl:text-[17px] text-white custom-font-400 uppercase">
                    Mountpoint - {baseId}
                </p>
            </div>
            <div className="flex items-center gap-x-[10px] lg:gap-x-[15px] xl:gap-x-[20px] mt-[20px]">
                <div className="rtk-main-sction-icon">
                    <img
                        src={rtkConnectivityIcon}
                        alt="icon"
                        className="lg:w-[22px] lg:h-[22px] xl:w-[28px] xl:h-[28px]"/>
                </div>
                <p className="text-[16px] xl:text-[18px] 2xl:text-[19px] text-white custom-font-600">
                    Server Parameters
                </p>
            </div>
            <div>
                <div
                    className="w-full mt-[30px] flex flex-row justify-between lg:justify-start lg:flex-col xl:flex-row xl:justify-between gap-y-[20px] gap-x-[5px]">
                    <div className="w-[60%] lg:w-full xl:w-[60%] 2xl:w-[55%]">
                        <p className="text-[14px] lg:text-[14px] text-light-gray mb-2 custom-font-400">
                            DNS
                        </p>
                        <p className="text-[16px] lg:text-[16px] xl:text-[14px] 2xl:text-[18px] text-white custom-font-400">
                            {ConnectionParameterService.getDNS()}
                        </p>
                    </div>
                    <div className="setting-left-border w-[40%] lg:w-full xl:w-[40%] 2xl:w-[45%]">
                        <div className="w-full">
                            <div className="flex flex-col items-start pl-[20px] lg:pl-0 xl:pl-[20px]">
                                <p className="text-[12px] lg:text-[14px] xl:text-[13px] 2xl:text-[14px] text-light-gray mb-2 custom-font-400">
                                    IP Address:
                                </p>
                                <p className="text-[16px] lg:text-[16px] xl:text-[14px] 2xl:text-[18px] text-white custom-font-400">
                                    {ConnectionParameterService.getIp()}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between items-end mt-[30px]">
                    <div className="w-[20%]">
                        <div className="flex flex-col items-start">
                            <p className="text-[14px] lg:text-[14px] text-light-gray mb-2 custom-font-400">
                                Port:
                            </p>
                            <p className="text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[18px] text-white custom-font-400">
                                {ConnectionParameterService.getPort()}
                            </p>
                        </div>
                    </div>
                    <div className="w-[75%] lg:w-full xl:w-[75%] lg:mt-[20px] xl:mt-0">
                        <Link to={URLGenerationService.useRTKPageURL}>
                            <div
                                className="bg-[#252525] rounded-[7px] hover:text-white hover:bg-transparent border border-[#252525] hover:border-[#4AFC2D]">
                                <button
                                    className="w-full gradient-text py-[9px] custom-font-400 text-[14px] rounded-[7px]">
                                    Get Login Credentials
                                </button>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className=" w-full mx-auto h-[1px] bg-[#424242] mt-[30px]"></div>
                <div
                    className="grid grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-[10px] lg:gap-[10px] mt-[30px] mb-[15px]">
                    <div>
                        <SidebarMiniStatsCard
                            bg={hotspotData?.online ? "rgba(74, 252, 45, 0.07)" : "#252525"}
                            title="Status"
                            defaultValue="Offline"
                            value={hotspotData?.online}
                            mapping={(i) => i ? "Online" : "Offline"}
                            statusIndicator={hotspotData?.online ? Green : Red}/>
                    </div>
                    <div>
                        <SidebarMiniStatsCard
                            bg="rgba(37, 37, 37, 1)"
                            title="Mining Performance"
                            defaultValue="-"
                            value={hotspotData?.performance}
                            mapping={(i) => `${(i * 100).toFixed(2)}%`}/>
                    </div>
                    <div>
                        <SidebarMiniStatsCard
                            bg="rgba(37, 37, 37, 1)"
                            title="Total Earning"
                            defaultValue="-"
                            value={hotspotData?.earnings}
                            mapping={(i) => `${i.toFixed(4)} RTK`}/>
                    </div>
                    <div>
                        <SidebarMiniStatsCard
                            bg="rgba(37, 37, 37, 1)"
                            title="24H Earning"
                            defaultValue="-"
                            value={hotspotData?.recentEarning}
                            mapping={(i) => `${i.toFixed(4)} RTK`}/>
                    </div>
                </div>
                {baseData?.history_areachart &&
                    baseData?.history_areachart?.length > 0 && (
                        <div>
                            <p className="text-[15px] lg:text-[16px] text-light-gray custom-font-400 mb-[8px]">
                                Satellite in use
                            </p>
                            <div className="sidebar-chart-div min-h-[300px] pt-[10px]">
                                <SatelliteLineChart data={baseData?.history_areachart}/>
                            </div>
                        </div>
                    )}
                {baseData?.history_linechart &&
                    baseData?.history_linechart.length > 0 && (
                        <div>
                            <p className="text-[15px]  lg:text-[16px] text-light-gray custom-font-400 mb-[8px] mt-[10px]">
                                DOP
                            </p>
                            <div className="sidebar-chart-div min-h-[300px] pt-[10px]">
                                <DopLineChart data={baseData?.history_linechart}/>
                            </div>
                        </div>
                    )}
                <div className="flex justify-between mt-[20px] mb-[15px] xl:mt-[20px]">
                    <p className="text-[14px] lg:text-[13px] xl:text-[15px] text-white custom-font-400">
                        Latest Activity
                    </p>
                    {/*                <button onClick={() => setActivityCount(activityCount === 3 ? activities.length : 3)}>
                  See {activityCount === 3 ? "more" : "less"}
                </button>*/}
                </div>
                <p className="flex justify-center text-red-600">Temporarily disabled</p>
                {/*              <div className="max-h-[400px] overflow-auto">
                <div>
                  {activities?.slice(0, activityCount)
                    .map((el: any, key: number) => {
                      return (
                        <div className="mb-[6px]" key={key}>
                          <RTKSidebarCard
                            title={el?.text}
                            subTitle={el?.timestamp}
                            index={key}/>
                        </div>
                      );
                    })}
                </div>
              </div>*/}
            </div>
        </div>
    );
};

export default SidebarBaseView;
