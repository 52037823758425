import React, {useMemo} from "react";
import {SatelliteData} from "../../../services/BaseService";
import BarChart, {BarChartData} from "./charts2/BarChart";

type SNRBARChartsProps = {
    data?: Map<string, SatelliteData>
};

type GoupedBarchartData = {
    G: BarChartData[];
    C: BarChartData[];
    R: BarChartData[];
    E: BarChartData[];
    J: BarChartData[];
};

const SNRBARCharts = ({data}: SNRBARChartsProps) => {
    function groupSNRS(data?: Map<string, SatelliteData>) {
        const groupedData: GoupedBarchartData = {
            G: [],
            C: [],
            R: [],
            E: [],
            J: [],
        };

        if (data === null || data === undefined) {
            return groupedData;
        }

        const entries: [string, SatelliteData][] = Object.entries(data)
        for (const [name, satelliteData] of entries) {
            const prefix = name.charAt(0)
            const snrData = satelliteData.snr;
            if (snrData == null) continue;
            const chartData: BarChartData = {
                name: name,
                L1: snrData.l1,
                L2: snrData.l2,
                L3: snrData.l5,
            }
            switch (prefix) {
                case "G":
                    groupedData.G.push(chartData)
                    break;
                case "C":
                    groupedData.C.push(chartData)
                    break;
                case "R":
                    groupedData.R.push(chartData)
                    break;
                case "E":
                    groupedData.E.push(chartData)
                    break;
                case "J":
                    groupedData.J.push(chartData)
                    break;
            }
        }
        return groupedData;
    }

    const snrs = useMemo(() => groupSNRS(data), [data]);
    return (<>
        <p className="block md:hidden text-[14px] text-[#D7D7D7] custom-font-400 pl-[10px]">GPS</p>
        <div className="gps-graph-div h-[260px] lg:h-[260px] p-[10px] overflow-hidden relative">
            <div className="absolute left-0 top-0">
                <p className="text-[11px] xl:text-[11px] 2xl:text-[12px] text-white custom-font-400 pl-[20px] pt-[10px] hidden lg:block">
                    GPS
                </p>
            </div>
            {snrs.G.length <= 0 ?
                <p className="h-full flex items-center justify-center text-red-600 w-full">Data unavailable</p> :
                <BarChart data={snrs.G}/>
            }
        </div>
        <p className="block md:hidden text-[14px] text-[#D7D7D7] custom-font-400 pl-[10px]">BDS</p>
        <div className="gps-graph-div h-[260px] p-[10px] overflow-hidden relative">
            <div className="absolute left-0 top-0">
                <p className="text-[11px] xl:text-[11px] 2xl:text-[12px] text-white custom-font-400 pl-[20px] pt-[10px] hidden lg:block">
                    BDS
                </p>
            </div>
            {snrs.C.length <= 0 ?
                <p className="h-full flex items-center justify-center text-red-600 w-full">Data unavailable</p> :
                <BarChart data={snrs.C}/>
            }
        </div>
        <p className="block md:hidden text-[14px] text-[#D7D7D7] custom-font-400 pl-[10px]">GLO</p>
        <div className="gps-graph-div h-[260px] p-[10px] overflow-hidden relative">
            <div className="absolute left-0 top-0">
                <p className="text-[11px] xl:text-[11px] 2xl:text-[12px] text-white custom-font-400 pl-[20px] pt-[10px] hidden lg:block">
                    GLO
                </p>
            </div>
            {snrs.R.length <= 0 ?
                <p className="h-full flex items-center justify-center text-red-600 w-full">Data unavailable</p> :
                <BarChart data={snrs.R}/>
            }
        </div>
        <p className="block md:hidden text-[14px] text-[#D7D7D7] custom-font-400 pl-[10px]">GAL</p>
        <div className="gps-graph-div h-[260px] p-[10px] overflow-hidden relative">
            <div className="absolute left-0 top-0">
                <p className="text-[11px] xl:text-[11px] 2xl:text-[12px] text-white custom-font-400 pl-[20px] pt-[10px] hidden lg:block">
                    GAL
                </p>
            </div>
            {snrs.E.length <= 0 ?
                <p className="h-full flex items-center justify-center text-red-600 w-full">Data unavailable</p> :
                <BarChart data={snrs.E}/>
            }
        </div>
        <p className="block md:hidden text-[14px] text-[#D7D7D7] custom-font-400 pl-[10px]">QZSS</p>
        <div className="gps-graph-div h-[260px] p-[10px] overflow-hidden relative">
            <div className="absolute left-0 top-0">
                <p className="text-[11px] xl:text-[11px] 2xl:text-[12px] text-white custom-font-400 pl-[20px] pt-[10px] hidden lg:block">
                    QZSS
                </p>
            </div>
            {snrs.J.length <= 0 ?
                <p className="h-full flex items-center justify-center text-red-600 w-full">Data unavailable</p> :
                <BarChart data={snrs.J}/>
            }
        </div>
    </>)
}

export default SNRBARCharts
