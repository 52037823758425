import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PreLoader from "../components/preLoader/PreLoader";
import HotspotBaseView from "../pages/HotspotBaseView";
import { URLGenerationService as urls } from "../services/URLGenerationService";

// Lazy load the components
const Login = lazy(() => import("../pages/Login"));
const Signup = lazy(() => import("../pages/Signup"));
const PasswordChangePage = lazy(() => import("../pages/PasswordChangePage"));
const BasicInfoPage = lazy(() => import("../pages/BasicInfoPage"));
const UseRTK = lazy(() => import("../pages/UseRTK"));
const UseRINEX = lazy(() => import("../pages/UseRINEX"));
const MyHotspots = lazy(() => import("../pages/MyHotspots"));
const Wallet = lazy(() => import("../pages/Wallet"));
const Main = lazy(() => import("../pages/Main"));
const MainInfo = lazy(() => import("../pages/MainInfoPage"));



const Config = () => {
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          <Route path="/" element={<Navigate to={urls.mainPageURL} />} />
          <Route path={urls.signUpPageURL} element={<Signup />} />
          <Route path={urls.logInPageURL} element={<Login />} />
          <Route path={urls.walletPageURL} element={<Wallet />} />
          <Route path={urls.useRTKPageURL} element={<UseRTK />} />
          <Route path={urls.useRinexPageURL} element={<UseRINEX />} />t
          <Route path={urls.hotspotsPageURL} element={<MyHotspots />} />
          <Route path={urls.getHotspotURL(":baseId")} element={<HotspotBaseView />} />
          <Route path={urls.mainPageURL} element={<Main />} />
          <Route path={urls.userInfoPageURL} element={<MainInfo />} />
          <Route path={urls.basicInfoPageURL} element={<BasicInfoPage />} />
          <Route path={urls.passwordChangePageURL} element={<PasswordChangePage />} />
{/*          <Route path="/test" element={<Layout>{{main: <>Hello</>, side: <>side</>}}</Layout>} />*/}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
