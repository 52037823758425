import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { SatelliteData } from "../../../services/BaseService";

type PolarData = {
  az: number;
  el: number;
  label: string;
};

const Container = styled.div`
  width: ${(props: { size: any }) => props.size};
  height: ${(props: { size: any }) => props.size};
  aspect-ratio: 1;
  position: relative;
`;

const Circle = styled.div`
  border: 1px solid #343434;
  border-radius: 50%;
  width: ${(props: { size: number }) => props.size * 100 + "%"};
  position: absolute;
  top: ${(props: { size: number }) => ((1 - props.size) * 100) / 2 + "%"};
  left: ${(props: { size: number }) => ((1 - props.size) * 100) / 2 + "%"};
  aspect-ratio: 1;
`;

const Line = styled.div`
  background: #343434;
  width: 1px;
  height: 100%;
  position: absolute;
  right: 50%;
  transform: rotate(${(props: { rotation: number }) => props.rotation + "deg"});
`;

const PointContainer = styled.div`
  width: 24px;
  height: 100%;
  position: absolute;
  right: calc(50% - 12px);
  transform: rotate(${(props: { az: number }) => props.az + "deg"});
`;

const Point = styled.div`
  font-size: 10px;
  background: #343434;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(${(props: { el: number }) => (props.el / 90) * 50}% - 12px);
  transform: rotate(
    ${(props: { el: number; az: number }) => -props.az + "deg"}
  );
  z-index: 1;
  cursor: pointer;
  @media (min-width: 1024px) and (max-width: 1535px) {
    width: 18px;
    height: 18px;
  }
`;

const PolarScatter = ({ data, filter, customWidth }: {data?: Map<string, SatelliteData>, filter: any, customWidth: any}) => {
  const [sats, setSats] = useState<Array<PolarData>>([]);
  const [hovering, setHovering] = useState<string>();

  useEffect(() => {
    if (!data) {
      setSats([])
      return;
    }

    const sat_data: PolarData[] = Object.entries(data).filter(([name, _]) => filter[name.substring(0, 1)]).map(([name, satData])=>{return {
      label: name,
      ...satData.pos,
      }})

    setSats(sat_data);
  }, [data, filter]);

  return (
    <Container size={customWidth}>
      <Circle size={3 / 3} />
      <Circle size={2 / 3} />
      <Circle size={1 / 3} />
      <Line rotation={0} />
      <Line rotation={30} />
      <Line rotation={60} />
      <Line rotation={90} />
      <Line rotation={120} />
      <Line rotation={150} />
      {sats.map((element, id) => {
        let flag;
        switch (element.label[0]) {
          case "C":
            flag = "cn";
            break;
          case "E":
            flag = "eu";
            break;
          case "R":
            flag = "ru";
            break;
          case "J":
            flag = "jp";
            break;
          default:
            flag = "us";
        }
        return (
          <PointContainer key={id} az={element.az}>
            <Point
              el={element.el}
              az={element.az}
              onMouseEnter={() => setHovering(element.label)}
              onMouseLeave={() =>
                setHovering(hovering === element.label ? "" : hovering)
              }
            >
              {hovering !== element.label ? (
                <img
                  src={`https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/${flag}.svg`}
                  alt="country flag"
                  className="w-[20px] lg:w-[18px] 2xl:w-[24px] h-[20px] lg:h-[18px] 2xl:h-[24px]"
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <h2 style={{ zIndex: "999", fontSize: "8px" }}>
                  {element.label}
                </h2>
              )}
            </Point>
          </PointContainer>
        );
      })}
    </Container>
  );
};
export default PolarScatter;
