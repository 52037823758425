import React, { useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";

const DopLineChart = ({ data }: any) => {
    const chartRef: any = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            const myChart = chartRef.current.getEchartsInstance();

            // Extract x-axis values and series data
            const xData = data?.map((item: any) => item.name);
            const seriesData = [
                {
                    name: "TDOP",
                    type: "line",
                    stack: "Total",
                    data: data.map((item: any) => item.GDOP),
                    lineStyle: {
                        width: 0.5, // Reduce line width to 0.5px
                        // color: "#FC2D2D", // Custom color for GDOP
                    },
                    symbol: "none", // Do not show symbols by default
                    emphasis: {
                        lineStyle: {
                            width: 1, // Increase line width on hover
                        },
                        showSymbol: true, // Show symbols on hover
                    },
                    color: "#FC2D2D",
                },
                {
                    name: "HDOP",
                    type: "line",
                    stack: "Total",
                    data: data.map((item: any) => item.HDOP),
                    lineStyle: {
                        width: 0.5, // Reduce line width to 0.5px
                    },
                    symbol: "none", // Do not show symbols by default
                    emphasis: {
                        lineStyle: {
                            width: 1, // Increase line width on hover
                        },
                        showSymbol: true, // Show symbols on hover
                    },
                    color: "#B7741B", // Custom color for HDOP
                },
                {
                    name: "VDOP",
                    type: "line",
                    stack: "Total",
                    data: data.map((item: any) => item.VDOP),
                    lineStyle: {
                        width: 0.5, // Reduce line width to 0.5px
                    },
                    symbol: "none", // Do not show symbols by default
                    emphasis: {
                        lineStyle: {
                            width: 1, // Increase line width on hover
                        },
                        showSymbol: true, // Show symbols on hover
                    },
                    color: "#249CF3", // Custom color for VDOP
                },
                {
                    name: "PDOP",
                    type: "line",
                    stack: "Total",
                    data: data.map((item: any) => item.PDOP),
                    lineStyle: {
                        width: 0.5,
                    },
                    symbol: "none",
                    emphasis: {
                        lineStyle: {
                            width: 1,
                        },
                        showSymbol: true,
                    },
                    color: "#4AFC2D",
                },
            ];

            const option = {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    data: ["PDOP", "VDOP", "HDOP", "TDOP"],
                    bottom: 0,
                    itemWidth: 12,
                    itemHeight: 12,
                    icon: "rect",
                    textStyle: {
                        color: "#FFF",
                        fontFamily: "Poppins",
                        fontSize: 12,
                        fontWeight: 400,
                    },
                },
                grid: {
                    left: "0%",
                    right: "4%",
                    bottom: "15%",
                    top: "25px",
                    containLabel: true,
                    borderColor: "#303030",
                    show: false,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: xData,
                    axisLabel: {
                        color: "#FFF",
                        fontFamily: "Poppins",
                        fontSize: 10,
                        fontWeight: 400,
                        margin: 15,
                    },
                    splitLine: {
                        show: false, // Show grid lines for the x-axis
                        lineStyle: {
                            color: "#303030", // Grid line color
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#FFF",
                            width: 0.5,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                },
                yAxis: {
                    type: "value",
                    show: false,
                    axisLabel: {
                        color: "#FFF",
                        fontFamily: "Poppins",
                        fontSize: 10,
                        fontWeight: 400,
                        margin: 10,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#FFF",
                            width: 0.5,
                        },
                    },
                    splitLine: {
                        show: false, // Show grid lines for the x-axis
                        lineStyle: {
                            color: "#303030", // Grid line color
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                },
                series: seriesData, // Use the series data we created earlier
            };

            myChart.setOption(option);
        }
    }, [data]);

    return (
        <div>
            <ReactECharts ref={chartRef} option={{}} style={{ height: "270px" }} />
        </div>
    );
};

export default DopLineChart;
