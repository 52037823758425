export const URLGenerationService = new (class {
    mainPageURL = "/main";
    
    signUpPageURL = "/signup";
    
    logInPageURL = "/login";
    
    walletPageURL = "/wallet";
    
    useRTKPageURL = "/use-rtk";
    
    useRinexPageURL = "/use-rinex"
    
    hotspotsPageURL = "/hotspots";
    
    hotspotPrefix = "/hotspots-base/"; 
    
    getHotspotURL(base: string) : string {
        return this.hotspotPrefix + base;
    }
    
    userInfoPageURL = "/user-info";
    
    basicInfoPageURL = "/basic-info";
    
    passwordChangePageURL = "/password-change";
})()
