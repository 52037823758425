import React from "react";

type DetailDumpProps<T> = {
    title: string;
    data: T | undefined | null;
    children: (input: T) => any
}

export const DetailDumpColumn = ({children}: any) => {
    return <div className="text-center grow basis-full">
        {children}
    </div>
}

const DetailDump = <T,>( {title, data, children}: DetailDumpProps<T>) => {
    return <div>
        <p className="ml-2 text-light-gray text-[14px] custom-font-400">{title}</p>
        <div className="location-section px-[10px] xl:px-[30px] py-[15px] mt-1 h-[100px] overflow-auto flex items-center">
            {data === null || data === undefined ? <p className="h-full flex items-center justify-center text-red-600 w-full">Data unavailable</p>: children(data)}
        </div>
    </div>
}

export default DetailDump;
