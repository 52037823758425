import {useState} from "react";
import Button from "./common/Button";
import MobileMenu from "./common/MobileMenu";
import {useNavigate} from "react-router-dom";
import {URLGenerationService} from "../services/URLGenerationService";
import {User} from "firebase/auth";

const BottomSidebar = ({loggedIn, children}: { loggedIn: User | null | undefined, children: any }) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const NoUser = () => {
        return (
            <div className="mobile-sidebar-hotspot-bg lg:mb-[30px]">
                <p className="text-white text-[16px] xl:text-[18px] text-center lg:text-left custom-font-600 mb-2 mt-[12px]">
                    Create New Account
                </p>
                <div className="w-full px-6">
                    <Button
                        text="Create Account"
                        bgColor="bg-gradient-to-r from-bg-green1 to-bg-green2"
                        fontColor="text-[#0F0F0F]"
                        textTransform="capitalize"
                        padding="py-[15px] px-6 lg:py-[12px] 2xl:py-[13px] lg:px-10 xl:px-16"
                        customFont="custom-font-400"
                        fontSize="text-[14px] xl:text-[14px]"
                        className="rounded-[13px] xl:rounded-[17px]"
                        handleClick={() => {
                            navigate(URLGenerationService.signUpPageURL);
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="fixed w-full bottom-0 bottom-menu-custom-z">
            <div
                className={`absolute bottom-[87px] left-0 block lg:hidden w-full mobile-sidebar-bg ${expanded ? "expand-height overflow-auto" : "contract-height"}`}>
                {!loggedIn ? <NoUser/> :
                    <div className="mb-[30px] px-4 lg:mt-0 baseview-sidebar-mobile-bg">
                        <div className="flex justify-center mb-4 lg:hidden mt-[10px] lg:mt-0">
                            {expanded ? (
                                <div onClick={() => setExpanded(false)}>
                                    <svg
                                        width="53"
                                        height="13"
                                        viewBox="0 0 53 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="rotate-180">
                                        <path
                                            d="M27 2.99997L51 11"
                                            stroke="#C8C8C8"
                                            strokeWidth="4"
                                            strokeLinecap="round"/>
                                        <path
                                            d="M2 11L26 2.99997"
                                            stroke="#C8C8C8"
                                            strokeWidth="4"
                                            strokeLinecap="round"/>
                                        <path
                                            d="M26.4662 2.87521L23.6863 3.7836"
                                            stroke="#C8C8C8"
                                            strokeWidth="4"
                                            strokeLinecap="round"/>
                                    </svg>
                                </div>
                            ) : (
                                <div onClick={() => setExpanded(true)}>
                                    <svg
                                        width="53"
                                        height="13"
                                        viewBox="0 0 53 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M27 2.99997L51 11"
                                            stroke="#C8C8C8"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                        />
                                        <path
                                            d="M2 11L26 2.99997"
                                            stroke="#C8C8C8"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                        />
                                        <path
                                            d="M26.4662 2.87521L23.6863 3.7836"
                                            stroke="#C8C8C8"
                                            strokeWidth="4"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                        {children}
                    </div>
                }
            </div>
            <div className="absolute bottom-0 left-0 w-full block lg:hidden custom-mobile-menu">
                <MobileMenu/>
            </div>
        </div>
    )
}

export default BottomSidebar
