import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import { URLGenerationService } from "../../services/URLGenerationService";

const BaseHotspotNoLogin = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col lg:items-center justify-between mt-[15%] rtk-custom-vh">
      <div className="flex-col w-full md:[50%] lg:w-[400px] text-center mt-[20%] md:mt-[10%] lg:mt-[0%]">
        <h1 className="text-white text-[24px] lg:text-[30px] xl:text-[35px] custom-font-600 mb-0">
          Want to Access
        </h1>
        <h1 className="gradient-text text-[24px] lg:text-[30px] xl:text-[35px] custom-font-600 mb-0">
          Base Data?
        </h1>
        <p className="text-lighter-white mt-2 lg:mt-6 xl:mt-10 mb-3 custom-font-400 text-[13px] lg:text-[16px] px-8 lg:px-0">
          Create a
          <span className="mx-2">
            <Link to={URLGenerationService.signUpPageURL} className="gradient-text hover:text-light-green">
              Free Account
            </Link>
          </span>
          to Access the RTK Direct Cloud Data
        </p>
        {/* for laptop */}
        <div className="">
          <div className="flex justify-center">
            <Button
              text="Create Account"
              bgColor="bg-gradient-to-r from-bg-green1 to-bg-green2"
              fontColor="text-[#0D0D0D]"
              textTransform="capitalize"
              padding="py-[16px] px-12"
              customFont="custom-font-400"
              fontSize="text-[16px]"
              className="rounded-[20px]"
              handleClick={() => {
                navigate(URLGenerationService.signUpPageURL);
              }}
            />
          </div>
          <p className="text-light-gray  mt-[95px] rtk-note text-[15px] lg:text-[15px] custom-font-400">
            Note: Yes Creating an Account is Free!
          </p>
        </div>
      </div>
    </div>
  );
};

export default BaseHotspotNoLogin;
