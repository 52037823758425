import React, {useState, useEffect, useContext} from "react";
import {Link, useParams} from "react-router-dom";
import MainTopbar from "../components/MainTopbar";
import SidebarBaseView from "../components/myHotspots/SidebarBaseView";
import PolarScatter from "../components/common/charts/PolarScatter";
import BaseHotspotNoLogin from "../components/myHotspots/BaseHotspotNoLogin";
import {Checkbox, Col, Row} from "antd";
import lines from "../assets/images/line-chart-grid.png";
import Sidebar from "../components/Sidebar";
import PromiseLoader, {usePromiseFunctionalLoader} from "../components/common/PromiseLoader";
import BottomSidebar from "../components/BottomSidebar";
import SNRBARCharts from "../components/common/charts/ SNRBARCharts";
import DetailDump, {DetailDumpColumn} from "../components/hotspotBase/DetailDump";
import {BaseData, BaseService} from "../services/BaseService";
import {AxiosError} from "axios";
import {URLGenerationService} from "../services/URLGenerationService";
import {AppContext} from "../App";


function hotspotBaseErrorHandler(error: any) {
    return <div className="flex flex-col justify-center align-center items-center">
        <p className="text-red-600">
            {(error.constructor === AxiosError && error.code === "ERR_BAD_REQUEST") ?
                "Base has not been found." :
                "An unknown error occured."}
        </p>
        <Link to={URLGenerationService.hotspotsPageURL}
              className=" grow-0 m-2 bg-carbon-black2 hover:bg-gradient-to-r from-bg-green1 to-bg-green2 text-white hover:text-[#0D0D0D] py-[14px] px-3 lg:py-[13px] xl:py-[13px] 2xl:py-[13px] xl:px-6 custom-font-400 text-[12px] lg:text-[12px] xl:text-[14px] 2xl:text-[15px] border border-[#4AFC2D] hover:border-transparent rounded-[17px] xl:rounded-[20px]">
            Back to Hotspots
        </Link>
    </div>
}

const HotspotBaseView = () => {
    const {baseId} = useParams();
    const hotspotLoader = usePromiseFunctionalLoader(baseId, BaseService.getBase)

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const loggedIn = useContext(AppContext)?.fireBaseUser;

    useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 1023px)");
        setIsSidebarOpen(mediaQuery.matches);

        const handleResize = (e: any) => {
            setIsSidebarOpen(e.matches);
        };

        mediaQuery.addEventListener("change", handleResize);

        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const [baseData,] = useState<any>(null);
    const [activityCount, setActivityCount] = useState(3);
    const [activities,] = useState<Array<any>>([]);
    const [filteredSatellites, setFilteredSatellites] = useState<any>({
        C: true,
        G: true,
        R: true,
        E: true,
        J: true,
    });

    const checkboxes = [
        {key: "C", label: "BDS(C)"},
        {key: "G", label: "GPS(G)"},
        {key: "R", label: "GLO(R)"},
        {key: "E", label: "GAL(E)"},
        {key: "J", label: "QZSS(J)"},
    ];

    const handleCheckboxChange = (key: any) => (ev: any) => {
        setFilteredSatellites({
            ...filteredSatellites,
            [key]: ev.target.checked,
        });
    };

    return (
        <div className="flex flex-col justtify-between h-screen overflow-hidden relative">
            <div className="overflow-auto">
                <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}>
                    <PromiseLoader loader={hotspotLoader.loader}
                                   error={null}
                                   loading={null}
                                   loaded={(hotspotData: BaseData) =>
                                       <SidebarBaseView
                                           baseId={baseId}
                                           hotspotData={hotspotData}
                                           baseData={baseData}
                                           activityCount={activityCount}
                                           setActivityCount={setActivityCount}
                                           activities={activities}/>}/>
                </Sidebar>
                <div className="flex-1 overflow-x-hidden overflow-y-auto">
                    <MainTopbar
                        toggleSidebar={toggleSidebar}
                        isSidebarOpen={isSidebarOpen}/>
                    <main
                        className="pb-[120px] lg:pb-0 px-4 lg:px-0 overflow-x-hidden overflow-y-auto h-full w-full lg:w-[76%] xl:w-[74%] z-40 lg:ml-[22.5%] xl:ml-[24.5%] mt-[100px] lg:mt-[120px] xl:mt-[130px] 2xl:mt-[135px] md:mr-[1%] xl-pr-[0px] 2xl:pr-[60px]">
                        <div className="flex flex-col justify-between">
                            {!loggedIn ? (
                                <BaseHotspotNoLogin/>
                            ) : (
                                <PromiseLoader loader={hotspotLoader.loader}
                                               loading={<div style={{height: "164px"}}>
                                                   <div className="loader"/>
                                               </div>}
                                               error={hotspotBaseErrorHandler}
                                               loaded={(hotspot) =>
                                                   <div className="flex flex-col justify-between">
                                                       <div className="pb-[150px] lg:pb-6 overflow-hidden">
                                                           <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px]">
                                                               <div
                                                                   className="country-graph-section p-[10px] lg:p-[20px] h-[350px] lg:h-[350px] overflow-hidden flex flex-col gap-y-[20px] lg:flex-row justify-between items-center">
                                                                   <div
                                                                       className="w-[70%] flex justify-center items-center">
                                                                       <PolarScatter
                                                                           data={hotspot.online ? hotspot.satellites : undefined}
                                                                           filter={filteredSatellites}
                                                                           customWidth="250px"/>
                                                                   </div>
                                                                   <div className="w-[100%] lg:w-[25%]">
                                                                       <Row
                                                                           className="flex flex-col lg:flex-row justify-center mb-[15px] skyplot-graph-from-baseview">
                                                                           {checkboxes.map((checkbox, index) => (
                                                                               <Col
                                                                                   key={checkbox.key}
                                                                                   sm={{span: 6}}
                                                                                   md={{span: 4}}
                                                                                   lg={{span: 24}}
                                                                                   xl={{span: 24}}
                                                                                   xxl={{span: 24}}
                                                                                   className={`${index !== 4 ? "mb-[10px] lg:mb-[35px]" : ""}`}>
                                                                                   <Checkbox
                                                                                       defaultChecked={filteredSatellites[checkbox.key]}
                                                                                       onChange={handleCheckboxChange(checkbox.key)}>
                                                                                       <p className="text-[14px] text-[#4E94FA] ml-[1px]">{checkbox.label}</p>
                                                                                   </Checkbox>
                                                                               </Col>
                                                                           ))}
                                                                       </Row>
                                                                   </div>
                                                               </div>
                                                               <div
                                                                   className="h-auto lg:h-[350px] overflow-auton flex flex-col gap-y-[20px] justify-between">
                                                                   <div
                                                                       className="uptime-graph-sections p-[10px] h-[170px] lg:h-[165px] overflow-hidden relative">
                                                                       <div className="absolute">
                                                                           <img src={lines} alt="lines"
                                                                                className="w-[100%]"/>
                                                                       </div>
                                                                       <div className="absolute left-0 top-0">
                                                                           <p className="text-[14px] xl:text-[15px] 2xl:text-[16px] text-white custom-font-400 pl-[20px] pt-[10px]">
                                                                               Uptime
                                                                           </p>
                                                                       </div>
                                                                       <p className="h-full flex items-center justify-center text-red-600">Temporarily
                                                                           disabled</p>
                                                                       {/*                                {baseData.history_uptime && baseData.history_uptime.length > 0 && (
                                  <UptimeLineChart data={baseData.history_uptime} />
                                )}*/}
                                                                   </div>
                                                                   <div
                                                                       className="uptime-graph-sections p-[10px] h-[170px] lg:h-[165px] overflow-hidden relative">
                                                                       <div className="absolute">
                                                                           <img src={lines} alt="lines"
                                                                                className="w-[100%]"/>
                                                                       </div>
                                                                       <div className="absolute left-0 top-0">
                                                                           <p className="text-[14px] xl:text-[15px] 2xl:text-[16px] text-white custom-font-400 pl-[20px] pt-[10px]">
                                                                               Mining Reward
                                                                           </p>
                                                                       </div>
                                                                       <p className="h-full flex items-center justify-center text-red-600">Temporarily
                                                                           disabled</p>
                                                                       {/*                          {baseData.history_reward && baseData.history_reward.length > 0 && (
                                                                    <MiningLineChart data={baseData.history_reward} />
                                                                  )}*/}
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <div
                                                               className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] mt-[10px]">
                                                               <DetailDump title="Location"
                                                                           data={hotspot.online ? hotspot.location : null}>{(location) => <>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[13px] xl:text-[14px] text-white custom-font-400 whitespace-nowrap">
                                                                           {location.latitude.toFixed(3)}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#898989] custom-font-400">±
                                                                           0.001 m</p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">Latitude</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[13px] xl:text-[14px] text-white custom-font-400 whitespace-nowrap">
                                                                           {location.longitude.toFixed(3)}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#898989] custom-font-400">±
                                                                           0.001 m</p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">Longitude</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[13px] xl:text-[14px] text-white custom-font-400 whitespace-nowrap">
                                                                           {location.altitude.toFixed(3)}
                                                                       </p>
                                                                       <p className="text-[12px] text-[@898989] custom-font-400">±
                                                                           0.001 m</p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">Altitude</p>
                                                                   </DetailDumpColumn>
                                                               </>}</DetailDump>
                                                               <DetailDump title="Satellites"
                                                                           data={hotspot.online ? hotspot.satelliteInfo : null}>{(satelliteInfo) => <>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[17px] text-white custom-font-500">
                                                                           {satelliteInfo.tracked}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">Tracked
                                                                           Satellites</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[17px] text-white custom-font-500">
                                                                           {satelliteInfo.inUse}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">Used
                                                                           Satellites</p>
                                                                   </DetailDumpColumn>
                                                               </>}</DetailDump>
                                                               <DetailDump title="Time" data={null}>{(_) => <>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[11px] lg:text-[13px] xl:text-[14px] text-white custom-font-400 whitespace-nowrap">
                                                                           --time_rover--
                                                                           {/*                            {baseData.status.time_rover.length > 10
                                                                    ? baseData.status.time_rover.substring(0, 10) +
                                                                      " " +
                                                                      baseData.status.time_rover.substring(10).split(".")[0]
                                                                    : "-"}*/}
                                                                       </p>
                                                                       <p className="text-[11px] text-[#D7D7D7] custom-font-400">UTC
                                                                           Date</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[11px] lg:text-[13px] xl:text-[14px] text-white custom-font-400 whitespace-nowrap">
                                                                           2244
                                                                       </p>
                                                                       <p className="text-[11px] text-[#D7D7D7] custom-font-400">GPS
                                                                           Week</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[11px] lg:text-[13px] xl:text-[14px] text-white custom-font-400 whitespace-nowrap">
                                                                           290676
                                                                       </p>
                                                                       <p className="text-[11px] text-[#D7D7D7] custom-font-400">GPS
                                                                           Seconds</p>
                                                                   </DetailDumpColumn>
                                                               </>}</DetailDump>
                                                               <DetailDump title="DOP Values"
                                                                           data={hotspot.online ? hotspot.dop : null}>{(dop) => <>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[14px] text-white custom-font-500">
                                                                           {dop.gdop}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">GDOP</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[14px] text-white custom-font-500">
                                                                           {dop.pdop}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">PDOP</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[14px] text-white custom-font-500">
                                                                           {dop.hdop}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">HDOP</p>
                                                                   </DetailDumpColumn>
                                                                   <DetailDumpColumn>
                                                                       <p className="text-[12px] lg:text-[14px] text-white custom-font-500">
                                                                           {dop.vdop}
                                                                       </p>
                                                                       <p className="text-[12px] text-[#D7D7D7] custom-font-400">VDOP</p>
                                                                   </DetailDumpColumn>
                                                               </>}</DetailDump>
                                                           </div>
                                                           <div
                                                               className="grid griid-cols-1 md:grid-cols-2 xl:grid-cols-5 gap-[20px] mt-[25px]">
                                                               <SNRBARCharts
                                                                   data={hotspot.online ? hotspot.satellites : undefined}/>
                                                           </div>
                                                           <p className="block md:hidden text-[14px] text-white custom-font-400 mt-[20px]">Satellite
                                                               Details</p>
                                                           <div
                                                               className="satellite-detail mt-[20px] px-[20px] py-[20px]">
                                                               <p className="hidden md:block text-[14px] text-white custom-font-400 mb-3">Satellite
                                                                   Details</p>
                                                               <div
                                                                   className="wallet-transection-table table-container max-h-[400px] lg:max-h-[440px] xl:max-h-[500px] overflow-auto">
                                                                   {(!hotspot.online || !hotspot.satellites) ?
                                                                       <p className="h-full flex items-center justify-center text-red-600 w-full">Data
                                                                           unavailable</p> :
                                                                       <table className="w-full">
                                                                           <thead className="mb-2">
                                                                           <tr>
                                                                               <td className="text-[14px] lg:text-[15px] pl-[20px]">Satellites</td>
                                                                               <td className="text-[14px] lg:text-[15px]">elevation</td>
                                                                               <td className="text-[14px] lg:text-[15px]">azimuth</td>
                                                                               <td className="text-[14px] lg:text-[15px]">SNR(L1)</td>
                                                                               <td className="text-[14px] lg:text-[15px]">SNR(L2)</td>
                                                                               <td className="text-[14px] lg:text-[15px]">SNR(L3)</td>
                                                                           </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                           {Object.entries(hotspot.satellites).map(([name, satelliteData]) => {
                                                                               if (!satelliteData.pos && !satelliteData.snr) return <> </>;
                                                                               return <tr key={name}>
                                                                                   <td className="text-[14px] text-white pl-[20px]">{name}</td>
                                                                                   <td className="text-[14px] text-white">{satelliteData.pos?.el ?? "-"}</td>
                                                                                   <td className="text-[14px] text-white">{satelliteData.pos?.az ?? "-"}</td>
                                                                                   <td className="text-[14px] text-white">{satelliteData.snr?.l1 ?? "-"}</td>
                                                                                   <td className="text-[14px] text-white">{satelliteData.snr?.l2 ?? "-"}</td>
                                                                                   <td className="text-[14px] text-white">{satelliteData.snr?.l5 ?? "-"}</td>
                                                                               </tr>
                                                                           })}
                                                                           </tbody>
                                                                       </table>}
                                                               </div>
                                                           </div>
                                                       </div>
                                                   </div>}/>
                            )}
                        </div>
                    </main>
                </div>
            </div>

            <BottomSidebar loggedIn={loggedIn}>
                <PromiseLoader loader={hotspotLoader.loader}
                               error={"Error message"}
                               loading={"Loading"}
                               loaded={(hotspotData) =>
                                   <SidebarBaseView
                                       baseId={baseId}
                                       hotspotData={hotspotData}
                                       baseData={baseData}
                                       activityCount={activityCount}
                                       setActivityCount={setActivityCount}
                                       activities={activities}/>}/>
            </BottomSidebar>
        </div>
    );
};

export default HotspotBaseView;
