import React, { useEffect, useRef } from "react";
import ReactECharts from "echarts-for-react";

const SatelliteLineChart = ({ data }: any) => {
  const chartRef: any = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = chartRef.current.getEchartsInstance();

      // Extract x-axis values and series data
      const xData = data?.map((item: any) => item.name);
      const seriesData = [
        {
          name: "GPS",
          type: "line",
          stack: "Total",
          areaStyle: {
            opacity: 1,
          },
          emphasis: {
            focus: "series",
          },
          data: data.map((item: any) => item.GPS),
          lineStyle: {
            width: 0.5,
            color: "#4AFC2D",
          },
          symbol: "none",
        },
        {
          name: "BDS",
          type: "line",
          stack: "Total",
          areaStyle: {
            opacity: 1,
          },
          emphasis: {
            focus: "series",
          },
          data: data.map((item: any) => item.BDS),
          lineStyle: {
            width: 0.5,
            color: "#FC2D2D",
          },
          symbol: "none",
        },
        {
          name: "GLO",
          type: "line",
          stack: "Total",
          areaStyle: {
            opacity: 1,
          },
          emphasis: {
            focus: "series",
          },
          data: data.map((item: any) => item.GLO),
          lineStyle: {
            width: 0.5,
            color: "#249CF3",
          },
          symbol: "none",
        },
        {
          name: "GAL",
          type: "line",
          stack: "Total",
          areaStyle: {
            opacity: 1,
          },
          emphasis: {
            focus: "series",
          },
          data: data.map((item: any) => item.GAL),
          lineStyle: {
            width: 0.5,
            color: "#B7741B",
          },
          symbol: "none",
        },
        {
          name: "QZSS",
          type: "line",
          stack: "Total",
          areaStyle: {
            opacity: 1,
          },
          emphasis: {
            focus: "series",
          },
          data: data.map((item: any) => item.QZSS),
          lineStyle: {
            width: 0.5,
            color: "#FFF",
          },
          symbol: "none",
        },
      ];

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          data: ["GPS", "BDS", "GLO", "GAL", "QZSS"],
          bottom: 0,
          itemWidth: 12,
          itemHeight: 12,
          icon: "rect",
          textStyle: {
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: 12,
            fontWeight: 400,
          },
        },

        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          top: "25px",
          containLabel: true,
          borderColor: "#303030",
          show: false,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLabel: {
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 10,
              fontWeight: 400,
              margin: 15,
            },
            splitLine: {
              show: false, // Show grid lines for the x-axis
              lineStyle: {
                color: "#303030", // Grid line color
              },
            },
            axisLine: {
              lineStyle: {
                color: "#FFF",
                width: 0.5,
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: 10,
              fontWeight: 400,
              margin: 10,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#FFF",
                width: 0.5,
              },
            },
            splitLine: {
              show: false, // Show grid lines for the x-axis
              lineStyle: {
                color: "#303030", // Grid line color
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: seriesData,
      };

      myChart.setOption(option);
    }
  }, [data]);
  return (
    <div>
      <ReactECharts ref={chartRef} option={{}} style={{ height: "270px" }} />
    </div>
  );
};

export default SatelliteLineChart;
